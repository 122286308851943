import React from 'react'
import { Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function FooterLogo() {

  const navigate = useNavigate();

  return (
   <Image style={{cursor:'pointer',maxHeight:'60px'}}fluid onClick={()=>navigate('/')} src='/logo/StringGeoLogoFooterBrand.png' />
  )
}

export default FooterLogo