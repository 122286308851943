import React, { useEffect, useState } from 'react'
import ContentTitle from '../../components/ContentTitle'
import { Button, Col, Container, Image, Row , Form, Spinner} from 'react-bootstrap'
import FormField from '../../components/FormField'
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { parsePhoneNumber } from 'libphonenumber-js';
import { imgAddr, useGetContactUsQuery, useSubmitQueryMutation } from '../../features/api';
import toast from 'react-hot-toast';
import { getError } from '../../utils/error';
import { useDispatch, useSelector } from 'react-redux';
import { resetContactFormData, selectContactUsData, updateContactFormData } from '../../features/contactUsSlice';

function ContactUs() {


  const contactFormData = useSelector(selectContactUsData)
  // const [contactFormData , setFormData] = useState({});
 const [submitQuery,{isLoading}] = useSubmitQueryMutation();
 const {data} = useGetContactUsQuery();
 const dispatch = useDispatch()
 const navigate = useNavigate() 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value)
    dispatch(updateContactFormData({ [name]: value }));
   
  };



  const handleFormSubmit = async (e) => {
    e.preventDefault();

    console.log(contactFormData?.mobile);

    if (contactFormData?.mobile) {
      const phoneNumber = parsePhoneNumber((contactFormData?.mobile?.replace(contactFormData?.dialCode, '')), contactFormData?.countryCode?.toUpperCase() );
      if (phoneNumber && phoneNumber.isValid()) {
          try {
              console.log("Form Data:", contactFormData)
            const data = await submitQuery({
              email: contactFormData?.email,
              name: contactFormData?.name,
              mobile: contactFormData?.mobile,
             address: contactFormData?.addr,
              company_name: contactFormData?.companyName,
              message: contactFormData?.message
            }).unwrap();
             
              toast.success(data?.message)
              dispatch(resetContactFormData());
              navigate('/')
  
          } catch (error) {  
            getError(error);  
            console.log('error submitting form',error);    
          }
          
     
      } else {
        toast.error('Enter a valid mobile number');
      }
    } else {
      toast.error('Enter Mobile number')
    }        
  };

  return (
    <section className='account-bg py-3'>
       <ContentTitle title='Contact Us' desc='Everything you need to know about the Platform and billing' />
    
      <Container>
        <Row className='flex-row-reverse'>
          
          <Col>
               <Form onSubmit={handleFormSubmit} className='text-white'>
                  {/* <h3 className='text-white'>Contact us</h3> */}
                  <p>Our friendly team would love to hear from you</p>

                  <Row>
                    <Col>
                      <FormField
                          label='Full name*'
                          placeholder='Full name'
                          name={'name'}
                          type={'text'}
                        
                          value={contactFormData.name}
                          onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                      <FormField
                          label='Company name'
                          placeholder='Company/organisation name'
                          name='companyName'
                          value={contactFormData.companyName}
                          onChange={handleInputChange}
                          required={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormField
                          label='Email*'
                          type='email'
                          placeholder='you@company.com'
                          name='email'
                          value={contactFormData.email}
                          onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                    
                <Form.Label className='fw-bold' style={{ fontSize: '0.8rem' }}>Mobile*</Form.Label>
                <PhoneInput
                
                inputClass='form-field text-white w-100'
                buttonClass='form-field'
                dropdownClass='text-black'
  country={'in'}
 enableSearch={true}
 countryCodeEditable={false}
 value={contactFormData?.mobile}
  
  onChange={(phone,code) =>{ 

    // setFormData((prevData)=> ({...prevData,  mobile: phone,countryCode: code.countryCode, dialCode:code.dialCode }));

    dispatch(updateContactFormData({
      mobile:phone,
      countryCode:code.countryCode,
      dialCode:code.dialCode
    })) 
    // console.log('in phone',code,phone);
  }
  }
/>
                
                      {/* <FormField
                          label='Phone number'
                          type='number'
                          placeholder='0000000000'
                          name='mobile'
                          value={contactFormData.mobile}
                          onChange={handleInputChange}
                      /> */}
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                      <FormField
                          label='Address*'
                          type='text'
                          placeholder='city,state,country'
                          name='addr'
                          value={contactFormData.addr}
                          onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormField
                          label='Message*'
                          as='textarea'
                          rows={5}
                          placeholder='Leave us a message...'
                          name='message'
                          value={contactFormData.message}
                          onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <Form.Check 
            type='checkbox'
            id='privacy-checkbox'
            label={<>You agree to our friendly <Link to={'/privacy-policy'} style={{color:'white'}}>Privacy policy</Link></>}
            required
            
          />
                    </Col>
                  </Row>
                <Row>
                  <Col>
                    <Button variant='transparent' disabled={isLoading}  className="text-white w-100 my-3 fw-bold form-btn" type='submit'>
                     {isLoading?<Spinner variant='border' size='sm'/>:'  Send message'} 
                      </Button>
                  </Col>
                </Row>

               </Form>
          </Col>

          <Col md={6}>
          <div>
            {data &&
            data?.contacts?.map((contact,index)=>(
              <Image key={contact?._id} src={`${imgAddr}/${contact?.image_url}`} className='rounded-4' style={{width:'100%',maxHeight:'550px'}} fluid />

            ))
            }

          </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ContactUs