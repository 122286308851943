import React, { useEffect, useState } from 'react';
import { Button, Carousel, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { IoPlayCircleOutline } from 'react-icons/io5'
import { FaChevronRight } from 'react-icons/fa6';
import {  imgAddr, useGetAllVideosMutation, useGetGenresQuery, useGetInnerCarouselQuery } from '../features/api';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { selectAuth } from '../features/authSlice';




function DashHomeCarousel() {

  const {data} = useGetInnerCarouselQuery();
  const [getAllVideos] = useGetAllVideosMutation();
  // const {data} = useGetGenresQuery();
  // const [term,setTerm] = useState(null);
  
  const [carousel,setCarousel] = useState([])
  const isMobileDevice = useMediaQuery({maxWidth: 600})

  const {accessToken,isActivePlan} = useSelector(selectAuth);



  const navigate = useNavigate();

   const hasAccess = accessToken && isActivePlan;
  
  useEffect(()=>{
    setCarousel(data?.carousels);
    // setTerm(data?.genres?.filter((genre)=>genre?.name === 'Carousel'));

  },[data])

  // useEffect(()=>{
  //    if(term){
  //     fetchCarouselData();
  //    }
  // },[term])

  // const fetchCarouselData = async()=>{
  //   try {
  //     console.log('fetching')
  //     const response = await getAllVideos(`?genres=${term[0]?._id}`).unwrap();
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

// useEffect(()=>{
//   console.log('cor genre',term)
// },[term])


  const handleViewVideo = (item)=>{
    
        if(item?.video_id?.access==='free' || isActivePlan){
          navigate(`/video/${item?.video_id?._id}`);
        }else{
          navigate('/auth/billing');
        }
      
  }

  return (
    <Carousel controls={false} pause={false}
    // style={{zIndex:'-1'}}
    className='poster'
    >
      {carousel?.map((item,index) => (
        <Carousel.Item 
         key={index}
          // style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.44) 20.27%, rgba(0, 0, 0, 0.44) 58.24%, rgba(0, 0, 0, 0.4356) 100.01%),linear-gradient(180.02deg, rgba(133, 126, 126, 0.18) 47.37%, rgba(6, 6, 6, 0.438) 75.24%, rgba(0, 0, 0, 0.6) 97.29%),linear-gradient(0deg, rgba(255, 255, 255, 0.29), rgba(255, 255, 255, 0.29)), url(${item?.poster_url})`, backgroundSize:'cover',backgroundRepeat:'no-repeat',height:'80vh' , }}
          style={{backgroundImage:`url(${imgAddr}/${item?.poster_url})`, backgroundSize:`${isMobileDevice?'100%':'100% 100%' } `,backgroundRepeat:'no-repeat',height:`${isMobileDevice?'50vh':'90vh'} ` , }}
          
         >
          <Carousel.Caption  >
            <Row className='d-flex justify-content-between'>
                <Col md={6}>
                <h1 className=' fw-bold'>{item?.video_id?.title}</h1>
                <p>{item?.video_id?.genres[0].name} | {new Date(item?.video_id?.createdAt).toLocaleDateString('en-GB')} | {item?.video_id?.language?.name}</p>
                </Col>
               
          
                <Col md={2} className='d-flex align-items-end'>
                <Button
                      variant="transparent"
                      className="text-white w-100 my-3 fw-bold form-btn "
                      //style={{zIndex:'999'}}
                      onClick={()=>handleViewVideo(item)}
                    >
                      Watch Now <IoPlayCircleOutline size={25}/>
                    </Button>
                </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
     
    </Carousel>
  );
}

export default DashHomeCarousel;
