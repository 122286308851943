import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import FooterLogo from './FooterLogo'
import './Footer.css';
import { FaFacebookF, FaGoogle,  FaInstagram,  FaLinkedinIn, FaXTwitter, FaYoutube } from 'react-icons/fa6'
import { FaTelegramPlane } from 'react-icons/fa';

function Footer() {

 const navigate = useNavigate();

    const googlePlayLink = "https://play.google.com/store/apps/details?id=your-app-package-name";
    const appleStoreLink = "https://apps.apple.com/us/app/your-app-id";
    
    const currentYear = new Date().getFullYear();

    const socialIcons = [
      { icon: FaFacebookF, link: 'https://www.facebook.com/m.vinodh.kumar'},
      { icon: FaInstagram, link: 'https://www.instagram.com/stringreveals' },
      { icon: FaTelegramPlane, link: 'https://t.me/StringOfficial' },
      { icon: FaXTwitter, link: 'https://twitter.com/StringReveals' },
      // { icon: FaYoutube, link: '' }
    ];

    
  return (
    <footer style={{background:'rgba(0, 0, 0, 0.1)'}} className=''>
        <Container className='py-5 '>
            <Row>
                <Col className=' text-lg-left text-center  my-2' lg={3}>
                <FooterLogo/> <br/>
                <Button variant='transparent' className='rounded-pill text-white px-md-5 mt-4 donate-btn text-nowrap' onClick={()=>navigate('/donate')} style={{  }}>Donate Now</Button>
                </Col>
                <Col className='my-2  nexa-light'>
<div className='mx-auto' style={{width:'fit-content'}}>
  <Link to='/about' style={{textDecoration:'none',color:'white'}} >About Us</Link><br/>
  {/* <Link to='/careers' style={{textDecoration:'none',color:'white'}}>Careers</Link><br/> */}
  <Link to='/legal' style={{textDecoration:'none',color:'white'}}>Legal</Link><br/>
  <Link to='/pricing-policy' style={{textDecoration:'none',color:'white'}}>Pricing Policy</Link><br/>
                </div>
                </Col>
                <Col lg={3} className='my-2 text-center'>
<p className='text-white text-center fs-5 fw-bold mb-0'>Download StringGeo app</p>
<Row className="">
      <Col>
        <Image src='/google-play-badge.jpg' fluid style={{height:'45px',aspectRatio:'150/50'}} />
      </Col>
      <Col >
      <Image src='/app-store-badge.svg' fluid style={{height:'45px',aspectRatio:'150/50'}}/>
      </Col>
    </Row>
      
                </Col>

                <Col className='text-white my-2 '>
                <div className='mx-auto' style={{width:'fit-content'}}>

                <p className=' fw-bold mb-0' >Need Help?</p>
                <Link to='/faq' style={{textDecoration:'none',color:'white'}} className=''>FAQ's</Link> <br/>
                <Link to='/contact-us' style={{textDecoration:'none',color:'white'}} className=''>Contact Us</Link>
               </div>
                </Col>

                <Col lg={3} className='my-2 px-lg-5'>
    
                <div className="social-icons-container px-2 ">
      {socialIcons?.map((socialIcon, index) => (
        <a href={socialIcon?.link} target="_blank" rel="noopener noreferrer" key={index}>
          <div className="social-icon">
            {<socialIcon.icon size={19} />}
          </div>
        </a>
      ))}
    </div>
    <br/>
   
    <div className='text-white' style={{ borderBottom: '2px solid white', width: '100%' }}></div>
   
    <p style={{color:'#CAA257'}} className='text-end mt-2 fs-5 fw-bold'>Connect With Us</p>
                </Col>
               
            </Row>
            <Row className='mt-3'>
                <Col className='text-white text-center' >
                <p >&copy; {currentYear} String Art Pvt. ltd. All rights reserved | <Link to={'/terms-condition'} style={{textDecoration:'none',color:'white'}}> Terms and Conditions</Link> | <Link to={'/privacy-policy'} style={{textDecoration:'none',color:'white'}}> Privacy policy</Link> | <Link to={'/refund-policy'} style={{textDecoration:'none',color:'white'}}> Refund policy</Link></p>
                </Col>
            </Row>
        </Container>
    </footer>
  )
}

export default Footer;