import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import VideoCard from './VideoCard';
import { useGetRecentPopularCategoriesMutation } from '../features/api';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

function HomeContent() {
    const [getRecentPopularCategories,{isLoading}] = useGetRecentPopularCategoriesMutation();
    const [videoData,setVideoData] = useState([]);
   
    
    const fetchData = async()=>{
        try {
            const data = await getRecentPopularCategories().unwrap();
            setVideoData(data?.categories);
        } catch (error) {
            console.log(error)
        }
    }
    
    useEffect(()=>{
       fetchData();
    },[])

   

  return (
    <section className='py-5 category-section' style={{
      backgroundSize:'100% 100%',
      backgroundRepeat:'no-repeat'}}>

<Container>
      {/* <h4 style={{ color: "#CAA257" }} className=" ms-5 mt-5 ">
        <BsStars /> Continue Watching
      </h4> */}

{videoData?.map((category,index)=>(
  <div key={category?._id}>
<Row className='mx-md-5 px-md-5 mt-4'>
        <Col>
        <h3 className="text-white d-inline">
        {category?.name}
      </h3>
      <Link to={`/search?category=${category?._id}`} className='float-end text-white'>See More <FaAngleRight/></Link>
        </Col>
        
      </Row>
<Row xs={2}  md={3}  className="mx-md-5 px-lg-5 videos-mapper">
{category?.video_array?.map((data, index) => (
  <Col key={data?.video?._id} className="p-md-4 d-flex p-1 align-items-center justify-content-center">
    <VideoCard
      id={data?.video?._id}
      title={data?.video?.title}
      description={data?.video?.description}
      // tags={video.tags.join(" | ")}
      thumbnail_url={data?.video?.thumbnail_url}
      // videoUrl={video.videoUrl}
      // genres={data?.video?.genres?.map(genre => genre?.name).join(' | ')}
      genres={data?.video?.genres[0]?.name}
      date={new Date(data?.video?.createdAt).toLocaleDateString('en-GB')}
       language={data?.video?.language?.name}
      loading={isLoading}
    />
  </Col>
))}
</Row>
</div>
))    

}


      
    </Container>

    
    </section>
  )
}

export default HomeContent