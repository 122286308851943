import React, { useEffect, useRef, useState } from 'react'
import { imgAddr } from '../features/api'
import ReactPlayer from 'react-player';
import { FaPause, FaPlay } from 'react-icons/fa6';
import { Button } from 'react-bootstrap';
import { IoMdShare } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';


function ShortsVideoPlayer({id,index,activeVideoIndex,videoUrl,longVideoUrl,title='lorem ipsum lore uais imkjiou yyg rrdfy ogh vgr es3 hoppp'}) {


  const navigate = useNavigate();
    const playerRef = useRef()
  
  const progressRef = useRef()
  const [played,setPlayed] = useState(0);
  const [playing,setPlaying] = useState(false);
  const [currentTime,setCurrentTime] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [oneLiner, setOneLiner] = useState(true);

  

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setPlaying(index === activeVideoIndex);
  }, [index, activeVideoIndex]);

  const togglePlayPause=()=>{
     setPlaying(!playing)
  }

    const handlePlayerReady = (index) => () => {
        if (index === activeVideoIndex) {
            const player = playerRef.current;
            if (player && typeof player.play === 'function') {
                player.seekTo(0); 
                player.play();
            }
        }
      };

      const handleProgress = (state) => {
        if (!progressRef.current) return;
        const { played, playedSeconds, loaded } = state;
      
        const progressPercentage = played * 100;
        const loadedPercentage = loaded * 100;
    
        setPlayed(progressPercentage);
      
        setCurrentTime(playedSeconds);
      
        const progressBar = progressRef.current;
        if (progressBar) {
          const progressColor = `linear-gradient(to right, #CAA257 ${progressPercentage + 0.1 }%, rgba(255,255,255,0.6) ${progressPercentage}%, rgba(255,255,255,0.6) ${loadedPercentage}%, rgba(255,255,255,0.2) ${loadedPercentage}%)`;
          progressBar.style.background = progressColor;
        }
      };
      
      const handleShare = async () => {
        try {
         
          const baseUrl = window.location.href.split('/').slice(0, -1).join('/'); // Extract base URL
          const sharedUrl = `${baseUrl}/${id}`;
      
          await navigator.share({
            title: 'Video Link',
            text: `Check out this short: ${title}`,
            url: sharedUrl,
            // files: [file], 
          });
        } catch (error) {
          console.error('Error sharing:', error);
        }
      };

  return (
  <div className='shorts-div ' onClick={togglePlayPause}>
     <ReactPlayer        
            controls={false}
            ref={playerRef}
            onProgress={handleProgress}
            url={`${imgAddr}/free-videos/${videoUrl}`}
            playing={playing}
            volume={1}
            // onProgress={handleProgress}
            loop
           pip={false}
           onReady={handlePlayerReady(index)}
            config={{
              hls: {
                forceHLS: true,
              },
            }}
          />
          <Button variant='transparent' className='play-pause-btn semi-trans' onClick={togglePlayPause}>{playing ?  <FaPause size={25}/>:<FaPlay size={20}/> }</Button>
        
          {longVideoUrl? <Button variant='transparent' className='full-vid-btn  p-1' onClick={()=>navigate(longVideoUrl)}><FaPlay className='mb-1' size={18}/>Watch Full Video</Button>:null}
        
          <Button disabled={!title} className=" share-btn semi-trans" variant="transparent" onClick={handleShare} ><IoMdShare  size={25}/> <br/> <span style={{fontSize:'0.75rem'}}>Share</span> </Button>
          
          <div className='shorts-controls'>
          <span className='shorts-title-container p-1'>
          <p  className={`shorts-title m-0 p-1 text-white ${expanded ? 'expanded' : ''}`}>
            {title?.substring(0,50)}
          </p>
          
          {/* { !oneLiner &&  <u  className='p-0 float-end m-0 read-more-button text-white' onClick={toggleExpansion}>
             {expanded?'Read Less': 'Read More'}
            </u>
} */}
        </span>
                  <input
            type="range"
            className='shorts-track'
            ref={progressRef}
            min={0}
            max={100}
            value={played}
            step='any'
          />


          </div>
    </div>
  )
}

export default ShortsVideoPlayer